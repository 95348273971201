@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .animation-delay-2000 {
    animation-delay: 2s;
  }
  .animation-delay-4000 {
    animation-delay: 4s;
  }
}

@layer base {
}

.firebase-emulator-warning {
  display: none;
}

#gradient-canvas {
  width: 100%;
  height: 100%;
  --gradient-color-1: #fef1f3;
  --gradient-color-2: #fee1e6;
  --gradient-color-3: #f0defd;
  --gradient-color-4: #fffbf2;
}

#gradient-canvas-form {
  width: 100%;
  height: 100%;
  --gradient-color-1: #c3e4ff;
  --gradient-color-2: #6ec3f4;
  --gradient-color-3: #eae2ff;
  --gradient-color-4: #b9beff;
}

.glass-card {
  backdrop-filter: blur(2px) saturate(100%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.6);
  border: 2.5px solid #fff;
}

.glass-button {
  all: unset;
  min-width: 100px;
  max-width: max-content;
  height: 30px;
  font-size: 16px;
  background: transparent;
  border: none;
  position: relative;
  color: #f0f0f0;
  cursor: pointer;
  z-index: 1;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.glass-button::after,
.glass-button::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -99999;
  transition: all 0.4s;
}

.glass-button::before {
  transform: translate(0%, 0%);
  width: 100%;
  height: 100%;
  background: #28282d;
  border-radius: 10px;
}

.glass-button::after {
  transform: translate(10px, 10px);
  width: 35px;
  height: 35px;
  background: #ffffff15;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 50px;
}

.glass-button:hover::before {
  transform: translate(5%, 20%);
  width: 110%;
  height: 110%;
}

.glass-button:hover::after {
  border-radius: 10px;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
}

.glass-button:active::after {
  transition: 0s;
  transform: translate(0, 5%);
}
